import React from "react";
import './App.css';

function App() {
  return (
    <div >
      <header className="App-header">
        <h1>WerkApp</h1>
        <p>An App For People Who Werk.</p>
      </header>
      <div>
      <div className="bod">
        <h2>A Netwerk...</h2>
        <p>WerkApp is a network for those who work. Create an account, request new associates and build a network. Easily schedule, organize and track jobs and worker with an easy to use mobile app for Apple or Android. WerkApp is the perfect silution for any contractor, scheduler, worker, or forman. It's like social media... but productive.</p>
        </div>
        <br/> 
        <br/>
        <br/>
        <br/>
        <br/>
        <h3>Privacy Policy</h3>
       

<p>Effective Date: 3/14/2024</p>

<h4>Introduction</h4>

<p>This Privacy Policy describes how WerkApp ("we," "us," or "our") collects, uses, and discloses your information when you use our mobile application ("App").
</p>
<h4>Information We Collect</h4>

<p>We collect two types of information through the App:</p>

Personal Information: This may include information that can be used to identify you, such as your name, email address, or username (if applicable). We only collect this information if you voluntarily provide it to us.
Non-Personal Information: This includes information that cannot be used to identify you, such as your device type, operating system, IP address, browsing activity within the App, and usage statistics. This information is collected automatically when you use the App.
How We Use Your Information

<p>We use the information we collect for the following purposes:</p>

<ul>
  <li>To operate and improve the App</li>
  <li>To personalize your experience with the App</li>
  <li>To send you promotional communications (if you opt-in)</li>
  <li>To respond to your inquiries and requests</li>
  <li>To comply with legal requirements</li>
  <li>Sharing Your Information
  </li>
</ul>


<p>We may share your information with third-party service providers who help us operate the App. These third parties are contractually obligated to keep your information confidential and to use it only for the purposes for which we disclose it to them.</p>
We will not share your personal information with any other third parties without your consent, except as required by law.

<h4>Data Retention</h4>

We will retain your information for as long as necessary to fulfill the purposes described in this Privacy Policy, unless a longer retention period is required or permitted by law.

<h4>Your Choices</h4>

You can opt out of receiving promotional communications from us by following the unsubscribe instructions in those communications.

<h4>Security</h4>

We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission or electronic storage is completely secure.

<h4>Children's Privacy</h4>

Our App is not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe that your child has provided us with personal information, please contact us.

<h4>Changes to This Privacy Policy</h4>

We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the App.

<h4>Contact Us</h4>

If you have any questions about this Privacy Policy, please contact us at:

<p>trey@pipedreamapps.com</p>
      </div>
    </div>
  );
}

export default App;
